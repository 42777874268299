const apexBarChart = Vue.component('apex-bar-chart', {
    components: {
        apexchart: window.VueApexCharts
    },
    data: function () {
        return {
            options: {
                chart: {
                    foreColor: '#2c3e50',
                    toolbar: {
                        show: false
                    }
                },
                colors: ["#e67e22"],
                dataLabels: {
                    enabled: true,
                    style: {
                        colors: ["#fff"]
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: '20%',
                        horizontal: false
                    }
                },
                tooltip: {
                    enabled: false
                },
                xaxis: {
                    categories: []
                }
            },
            series: [
                {
                    name: '',
                    data: []
                }
            ]
        };
    },
    methods: {

    },
    props: {
        title: {
            required: false,
            type: String
        },
        xaxis: {
            required: true,
            type: Array
        },
        yaxis: {
            required: true,
            type: Array
        }
    },
    template:
        `   
            <div class="chart-component">
                <div v-if="title" class="chart-header">
                    <h2 class="title">{{title}}</h2>
                </div>
                <div>
                    <apexchart ref="chart" height="300" type="bar" :options="options" :series="series"></apexchart>
                </div>
            </div>
        `,
    watch: {
        xaxis: function (newVal) {
            this.$refs.chart.updateOptions({
                xaxis: {
                    categories: newVal
                }
            });
        },
        yaxis: function (newVal) {
            this.series = newVal;
        }
    }
});

const confirmationComponent = Vue.component('confirmation-component', {
    methods: {
        cancel() {
            this.$emit('cancel');
        },
        confirm() {
            this.$emit('confirm');
        }
    },
    props: {
        header: {
            required: true,
            type: String
        },
        body: {
            required: true,
            type:String
        }
    },
    template:
        `
            <transition name="modal-fade">
                <div class="overlay">
                    <div class="modal-component">
                        <div class="header">
                            <i class="fas fa-info-circle fa-fw fa-2x"></i>
                        </div>
                        <div class="body">
                            <h3>{{header}}</h3>
                            <p>
                                {{body}}
                            </p>
                        </div>
                        <div class="footer">
                            <button class="btn btn-outline-primary" v-on:click="confirm">Confirm</button>
                            <button class="btn btn-outline-danger" v-on:click="cancel">Cancel</button>
                        </div>
                    </div>  
                </div>
            </transition>
        `
});
const galleryComponent = Vue.component('gallery-component', {
    created() {
        this.parseOptions();
        common.get(this.getUrl())
            .then(response => {
                let groups = {};
                response.value.forEach(medialink => {
                    if (this.gallery_options.grouping.enabled) {
                        const groupEntity = this.gallery_options.grouping.entity;
                        let group = medialink[groupEntity];
                        delete medialink[groupEntity];

                        if (groups[group.NormalizedName]) {
                            groups[group.NormalizedName].data.push(medialink);
                        }
                        else {
                            groups[group.NormalizedName] = {
                                Name: group.Name,
                                NormalizedName: group.NormalizedName,
                                data: [medialink]
                            };
                        }
                    }
                    else {
                        this.data.push(medialink);
                    }
                });

                if (this.gallery_options.grouping.enabled) {
                    for (let group in groups) {
                        this.data.push({
                            Name: groups[group].Name,
                            NormalizedName: groups[group].NormalizedName,
                            data: groups[group].data
                        });
                    }
                }

                this.success = true;

            })
            .catch(error => console.error(error));
    },
    data() {
        return {
            data: [],
            gallery_options: {
                actions: {
                    remove: { enabled: false, url: '' }
                },
                endpoint: '',
                grouping: {
                    enabled: false,
                    entity: null
                },
                key: '',
                ribbon: []//format {icon:'fas fa-plus', url:'http://domain.com/action'}
            },
            loading: false,
            open: false,
            selected: null,
            selectedImage: null,
            success: false
        };
    },
    methods: {
        clearSelection() {
            this.selected = null;
        },
        close() {
            this.selectedImage = null;
            this.open = false;
        },
        display(val) {
            this.loading = true;
            let image = new Image();

            this.selectedImage = val;
            image.src = val.Url;
            image.onload = () => {
                this.loading = false;
            };
            this.open = true;
        },
        expand(base, value) {
            let obj = {};
            for (const key in base) {
                if (value[key] !== undefined) {
                    if (typeof value[key] === "object" && !Array.isArray(value[key])) {
                        obj[key] = this.expand(base[key], value[key]);
                    }
                    else {
                        obj[key] = value[key];
                    }
                } else {
                    obj[key] = base[key];
                }
            }

            return obj;
        },
        parseOptions() {
            this.gallery_options = this.expand(this.gallery_options, this.options);
        },
        getUrl() {
            if (this.gallery_options.grouping.enabled) {
                return `${this.gallery_options.endpoint}?$expand=${this.gallery_options.grouping.entity}`;
            }
            else {
                return this.gallery_options.endpoint;
            }
        },
        select(val) {
            this.selected = val;
        }
    },
    props: {
        options: {
            required: true,
            type: Object
        }
    },
    template: `
        <div v-if="success" class="gallery-component">
                <div class="table-ribbon">
                    <a v-for="action in gallery_options.ribbon" v-bind:href="action.url" class="item">
                        <i class="fa-fw" v-bind:class="action.icon"></i>
                    </a>
                </div>
                <div class="body">
                    <div v-if="selected != null" class="gallery-container" >
                        <div class="gallery-image clickable -primary" v-on:click="clearSelection">
                            <div class="button" >
                                <div><i class="far fa-arrow-up fa-fw fa-3x"></i></div>
                            </div>
                        </div>
                        <div v-for="image in data[selected].data" class="gallery-image clickable" >
                            <a :href="'delete/'+image.Id" class="fa-stack clickable remove">
                              <i class="fas fa-square fa-stack-2x"></i>
                              <i class="fas fa-trash-alt fa-stack-1x fa-inverse"></i>
                            </a>
                            <div class="child" v-bind:style="{backgroundImage:'url('+image.Thumbnail+')'}" v-on:click="display(image)">
                            </div>
                        </div>
                    </div>
                    <div v-else   class="gallery-container">
                        <div v-for="(group,index) in data" class="group-thumbnail" v-on:click="select(index)">
                            <div class="body">
                                {{group.data.length}}
                            </div>
                            <div class="title">
                               <i class="far fa-folder fa-fw"></i> {{group.Name}}
                            </div>
                        </div>
                    </div>
                    <div v-if="data.length===0" class="empty-panel">
                        <div class="header">
                            <i class="far fa-info-circle fa-fw fa-2x"></i>
                        </div>
                        <div class="body">
                            There is no data to show.
                        </div>
                    </div>
                </div>
                <transition name="modal-fade">
                    <div v-if="selectedImage" class="overlay" v-bind:class="{'d-none':!open}">
                        <div class="image-container">
                            <div class="inner">                         
                                <div class="header">
                                    {{selectedImage.Name}}
                                    <i class="far fa-times fa-fw clickable" v-on:click="close"></i>
                                </div>
                                <div class="body">
                                    <i v-if="loading" class="fas fa-spinner fa-pulse fa-2x"></i>
                                    <img v-bind:src="selectedImage.Url"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
        </div>
        <div v-else class="loading-panel">
            <i class="fas fa-sync fa-spin fa-fw fa-2x"></i>
        </div>
    `
});
const galleryPickerComponent = Vue.component('gallery-picker-component', {
    data: function () {
        return {
            data: {},
            selected: null,
            show: false
        };
    },
    methods: {
        close() {
            this.show = false;
        },
        getHeight(val) {
            if (this.selected !== val) {
                return '0px';
            }
            else {
                return `${this.$refs[val][0].scrollHeight}px`;
            }
        },
        pick(val) {
            this.update(val.Url);
            this.close();
        },
        removeSelection() {
            this.update('');
        },
        selectGroup(val) {
            if (this.selected === val) {
                this.selected = null;
            }
            else {
                this.selected = val;
            }
        },
        toggleOpen() {
            this.show = !this.show;
        },
        update(val) {
            val = val === undefined ? this.$refs.galleryInput.value : val;

            this.$emit('input', val);
        }
    },
    props: {
        source: {
            required: true,
            type: Array
        },
        value: {
            required: true,
            type: String
        }
    },
    template:
        `   <div class="gallery-picker-component" v-click-outside="close">
                <div class="input-group clickable" >
                    <div class="input-group-prepend" v-on:click="toggleOpen">
                        <span class="input-group-text -primary">
                            <i class="fas fa-image fa-fw"></i>
                        </span>
                    </div>
                    <input type="text" ref="galleryInput" class="form-control" :value="value" v-on:input="update()"  disabled/>
                    <div v-if="value.length !== 0" class="input-group-append" v-on:click="removeSelection">
                        <span class="input-group-text -danger">
                            <i class="fal fa-times fa-fw"></i>
                        </span>
                    </div>
                </div>
                <div class="gallery-picker" :class="{'-open':show,'-hide':!show}">
                    <div v-for="(value,key) in data" class="gallery-picker-group" :class="{'-selected':selected===key}">
                        <div class="header clickable" v-on:click="selectGroup(key)">
                            {{key}}
                        </div>
                        <div  class="body" :ref="key" :style="{height:getHeight(key)}">
                            <div v-on:click="pick(image)" v-for="image in value" class="thumbnail clickable" :style="{backgroundImage:'Url('+image.Thumbnail+')'}">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `,
    watch: {
        show: function () {
            if (this.show === false) {
                this.selected = null;
            }
        },
        source: function () {
            this.data = {};
            this.source.forEach(image => {
                let group = image.Group.Name;
                if (!this.data[group]) {
                    this.data[group] = [];
                }
                this.data[group].push(image);
            });
        }
    }
});
const storageBarComponent = Vue.component('storage-bar-component', {
    methods: {
        format(value) {
            const length = String(value).length;
            if (length >= 10) {
                return `${this.formatNumber(value / Math.pow(1024,3))} GB`;
            }
            else if (length >= 7) {
                return `${this.formatNumber(value / Math.pow(1024, 2))} MB`;
            }
            else {
                return `${this.formatNumber(value / 1024)} KB`;
            }
        },
        formatNumber(value) {
            return value.toLocaleString('en-US',
                {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1
                });
        },
        getClass() {
            let percentage = this.getPercentage();
            if (percentage > 75) {
                return "-warning";
            } else if (percentage > 45) {
                return "-ok";
            }
            else {
                return "-good";
            }
        },
        getPercentage() {
            return (this.used / this.max * 100) || 0;
        },
        getUsedString() {
            return `${this.used / 100 * this.max.value}${this.unit}`;
        }
    },
    props: {
        max: {
            required: true,
            type: Number
        },
        used: {
            required: true,
            type: Number
        }
    },
    template:
        `   <div class="storage-bar-container">
                <div class="values">
                    <div class="item monofont">
                        {{format(used)}}
                    </div>
                    <div class="item -right monofont">
                        {{format(max)}}
                    </div>
                </div>
                <div class="storage-bar">
                    <div class="used" v-bind:style="{width:getPercentage()+'%'}"  v-bind:class="getClass()"></div>
                    <div class="available"></div>
                </div>
                <div class="info monofont">
                    <span class="text-secondary"><strong>{{formatNumber(getPercentage())+'%'}}</strong></span> of storage used
                </div>
            </div>
        `
});
const tableComponent = Vue.component('table-component', {
    data: function () {
        return {
            count: 0,
            data: [],
            page: 0,
            headers: [],
            order: null,
            performance: "0 ms",
            success: false,
            table_options: {
                dataset: {
                    actions: {
                        edit: { enabled: false, url: '' },
                        details: { enabled: false, url: '' },
                        remove: { enabled: false, url: '' }
                    },
                    hide:[]
                },
                endpoint: null,
                key: '',
                order: null,
                pageSize: 10,
                ribbon: []//format {icon:'fas fa-plus', url:'http://domain.com/action'}
            }
        };
    },
    computed: {
        totalPages: function () {
            return parseInt(Math.ceil(this.count / this.table_options.pageSize));
        },
        hasNextPage: function () {
            return this.page < this.totalPages;
        },
        hasPrevPage: function () {
            return this.page > 1;
        }
    },
    methods: {
        expand(base, value) {
            let obj = {};
            for (const key in base) {
                if (value[key] !== undefined) {
                    if (typeof value[key] === "object" && !Array.isArray(value[key])) {
                        obj[key] = this.expand(base[key], value[key]);
                    }
                    else {
                        obj[key] = value[key];
                    }
                } else {
                    obj[key] = base[key];
                }
            }

            return obj;
        },
        format(data) {
            //TODO 
            /*
             * This function is to possibly be able to format data based on
             * a schema provided in the options property
             * For example:
             * 1. Image links could be displayed as images.
             * 2. Text could be displayed as a badge.
             * 3. Possible conditional formatting.
             */
            return data;
        },
        getData() {
            let start = performance.now();
            let end;
            common.get(`${this.table_options.endpoint}?$top=${this.table_options.pageSize}&$skip=${(this.page - 1) * this.table_options.pageSize}${this.getOrderString()}`)
                .then(data => {
                    end = performance.now();
                    this.performance = `${(end - start).toFixed(0)} ms`;
                    this.data = this.format(data.value);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getHeaders() {
            let headers = [];
            if (this.data.length > 0) {
                for (let key in this.data[0]) {
                    if (this.table_options.dataset.hide.indexOf(key) === -1) {
                        headers.push(key);
                    }
                }
            }
            this.headers = headers;
        },
        getHeaderPercentage() {
            return 100 / this.headers.length;
        },
        getOrderString() {
            if (this.order) {
                if (this.order.direction === 1) {
                    return `&$orderby=${this.order.header}`;
                }
                else {
                    return `&$orderby=${this.order.header} desc`;
                }
            }
            return '';
        },
        nextPage() {
            this.page += 1;
            this.getData();
        },
        pageEnd() {
            let brutePageEnd = this.table_options.pageSize * this.page;
            return brutePageEnd > this.count ? this.count : brutePageEnd;
        },
        pageStart() {
            let brutePageStart = (this.page - 1) * this.table_options.pageSize + 1;
            return this.count < brutePageStart ? this.count : brutePageStart > 0 ? brutePageStart:0;
        },
        parseOptions() {
            this.table_options = this.expand(this.table_options, this.options);
        },
        prevPage() {
            this.page -= 1;
            this.getData();
        },
        updateOrder(value) {
            if (this.order && this.order.header === value) {
                if (this.order.direction + 1 > 2) {
                    this.order = null;
                }
                else {
                    this.order.direction += 1;
                }
            }
            else {
                this.order = {
                    header: value,
                    direction: 1
                };
            }

            this.getData();
        }
    },
    created() {
        this.parseOptions();
        let start = performance.now();
        let end;
        common.get(`${this.table_options.endpoint}?$count=true&$top=${this.table_options.pageSize}${this.getOrderString()}`)
            .then(data => {
                end = performance.now();
                this.performance = `${(end - start).toFixed(0)} ms`;
                this.count = data['@odata.count'];
                if (data.value.length > 0) {
                    this.page = 1;
                    this.data = this.format(data.value);
                }
                this.getHeaders();
                this.success = true;
            })
            .catch(error => {
                console.error(error);
            });
    },
    props: {
        options: {
            required: true,
            type: Object
        }
    },
    template:
        `
            <div v-if="success" class="table-component">
                <div class="table-ribbon">
                    <a v-for="action in table_options.ribbon" v-bind:href="action.url" class="item">
                        <i class="fa-fw" v-bind:class="action.icon"></i>
                    </a>
                </div>
                <div class="table-responsive">
                    <table class="table bc-table">
                        <thead class="header">
                            <tr>
                                <th v-for="header in headers" class="clickable" v-on:click="updateOrder(header)" v-bind:style="{width:getHeaderPercentage()+'%'}">
                                    {{header}} 
                                    <i v-if="order && order.header === header" class="fas fa-fw" :class="order.direction===1?'fa-caret-up':'fa-caret-down'"></i>
                                </th>
                                <th v-if="table_options.dataset.actions.edit.enabled"></th>
                                <th v-if="table_options.dataset.actions.details.enabled"></th>
                                <th v-if="table_options.dataset.actions.remove.enabled"></th>
                            </tr>
                        </thead>
                        <tbody class="body">
                            <tr v-for="row in data">
                                <td v-if="table_options.dataset.hide.indexOf(name) === -1" v-for="(value,name) in row">{{value}}</td>
                                <td v-if="table_options.dataset.actions.edit.enabled" class="button">
                                    <a :href="table_options.dataset.actions.edit.url+'/'+row[table_options.key]">
                                        <i class="fas fa-edit fa-fw"></i>
                                    </a>
                                </td>
                                <td v-if="table_options.dataset.actions.details.enabled" class="button">
                                    <a :href="table_options.dataset.actions.details.url+'/'+row[table_options.key]">
                                        <i class="fas fa-file-alt fa-fw"></i>
                                    </a>
                                </td>
                                <td v-if="table_options.dataset.actions.remove.enabled" class="button">
                                    <a :href="table_options.dataset.actions.remove.url+'/'+row[table_options.key]">
                                        <i class="fas fa-trash-alt fa-fw"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="data.length===0" class="empty-panel">
                    <div class="header">
                        <i class="far fa-info-circle fa-fw fa-2x"></i>
                    </div>
                    <div class="body">
                        There is no data to show.
                    </div>
                </div>
                <div class="table-footer monofont">
                    <div class="table-info-controls d-none d-sm-block">
                        <div class="item">
                            {{pageStart()}} - {{pageEnd()}} of {{count}} items
                            <small><i class="far fa-clock fa-fw item"></i> {{performance}}</small>
                        </div>
                    </div>
                    <div class="pagination-controls">
                        <i class="far fa-chevron-left fa-fw item button clickable" v-if="hasPrevPage" v-on:click="prevPage"></i>
                        <i class="far fa-genderless fa-fw item" v-else></i>
                        <span class="info">{{page}} <span v-if="totalPages>0">of {{totalPages}}</span></span>
                        <i class="far fa-chevron-right fa-fw item button clickable" v-if="hasNextPage" v-on:click="nextPage"></i>
                        <i class="far fa-genderless fa-fw item" v-else></i>
                    </div>
                </div>
            </div>
            <div v-else class="loading-panel">
                <i class="fas fa-sync fa-spin fa-fw fa-2x"></i>
            </div>

        `
});

const uploadComponent = Vue.component('upload-component', {
    data: function () {
        return {
            url: null,
            type: /image\/*/i
        };
    },
    methods: {
        addFile(e) {
            let file = e.dataTransfer.files[0];
            if (file.type.match(this.type)) {
                this.handleFile(file);
            }
        },
        clear() {
            this.$emit('input', null);
        },
        handleFile(file) {
            this.url = URL.createObjectURL(file);
            this.$emit('input', file);
        },
        handleFileChange(e) {
            const file = e.target.files[0];
            this.handleFile(file);
        },
        toKb(val) {
            return Math.floor(val / 1024);
        }
    },
    props: {
        value: File
    },
    template: `
        <label class="file-upload" v-on:drop.prevent="addFile" v-on:dragover.prevent>
            <div v-if="value" class="file-preview-window">   
                <div class="thumbnail">
                    <img v-bind:src="url"/>
                </div>
                <div class="file-info-box">
                    <div class="header">
                        Selected File
                    </div>
                    <div class="body">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Size</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{value.name}}</td>
                                    <td>{{value.type}}</td>
                                    <td>{{toKb(value.size)}} kb</td>
                                    <td><i class="fas fa-trash-alt fa-fw clickable" v-on:click.prevent="clear" title="clear media"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="placeholder" v-else>
                <div class="header">
                    <i class="fas fa-upload fa-2x"></i>
                </div>
                <div class="body">
                    Drop or click to select file
                </div>
            </div>
            <input class="d-none" type="file" v-on:change="handleFileChange" accept="image/*" />
        </label>
    `
});